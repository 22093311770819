import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { User } from "../models/auth.models";
import { EcrfAuthService } from "./ecrf-auth.service";

@Injectable({
  providedIn: "root",
})
export class RoleService {
  user: User;

  constructor(private router: Router) { }

  returnRole() {
    this.user = JSON.parse(localStorage.getItem("currentUser"));

    if (this.user != null && this.user?.role != null) {
      if (this.user?.role === "LAB_USER"){
        return "LAB_USER";
      }
      if (this.user?.role === "SUPER_ADMIN" || this.user?.role === "ADMIN"){
        return "ADMIN";
      }
      if (this.user?.role === "USER") {
        return "USER"
      }
      if (this.user?.role === "LAB_ADMIN"){
          return "LAB_ADMIN"
        }
      if (this.user?.role === "BIO_INFORMATICIAN"){
          return "BIO_INFORMATICIAN"
      }
      if (this.user?.role === "DOCTOR_USER"){
          return "DOCTOR_USER"
        }
        if (this.user?.role === "ACCESS_USER"){
          return "ACCESS_USER"
        }
    }
    localStorage.clear();
    this.router.navigate(["/account/login"]);
    return null;
  }
}

// ADMIN,
// USER,
// SUPER_ADMIN,
// LAB_USER,
// INTEGRATION
